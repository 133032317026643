<template>
  <div class="lookcasedetail-container">
    <div class="lookcasedetail-tab">
      <div class="lookcasedetail-tab-item">
        <div class="lookcasedetail-tab-item-label">受理号</div>
        <div class="lookcasedetail-tab-item-content">
          {{ caseInfo.acceptNo }}
        </div>
      </div>
      <div class="lookcasedetail-tab-item">
        <div class="lookcasedetail-tab-item-label">申请调解人</div>
        <div class="lookcasedetail-tab-item-content">
          {{ mediatorname }}
        </div>
      </div>
      <div class="lookcasedetail-tab-item">
        <div class="lookcasedetail-tab-item-label">被申请调解人</div>
        <div class="lookcasedetail-tab-item-content">{{ respondentsname }}</div>
      </div>
    </div>
    <div class="lookcasedetail-step">
      <el-steps :active="active" finish-status="success" simple>
        <el-step title="申请"></el-step>
        <el-step title="受理"></el-step>
        <el-step title="答辩"></el-step>
        <el-step title="司法确认"></el-step>
        <el-step title="结案"></el-step>
      </el-steps>
    </div>
    <div class="lookcasedetail-menu">
      <el-tabs v-model="activeMenu" type="card" @tab-click="handleClick">
        <el-tab-pane label="送达详情">
          <div
            class="lookcasedetailData-content"
            v-if="lookcasedetailData.length > 0"
          >
            <div
              class="lookcasedetailData-item"
              v-for="(item, index) in lookcasedetailData"
              :key="index"
            >
              <div class="item-content">{{ item.content }}</div>
              <div class="item-serviceName">{{ item.name }}</div>
              <div class="item-serviceStatus">
                <span v-show="item.status == 0">待送达</span>
                <span v-show="item.status == 1">已送达</span>
                <span v-show="item.status == 2">送达失败 </span>
              </div>
              <div class="item-serviceTime">{{ item.sendTime }}</div>
            </div>
          </div>
          <div class="empty" v-else>
            <div class="empty-img">
              <img src="../assets/images/index/empty.png" />
            </div>
            <div class="empty-text">暂无相关数据</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="申请调解人材料">
          <div
            class="applicationData-content"
            v-if="applicationData.length > 0"
          >
            <div
              class="applicationData-item"
              v-for="(item, index) in applicationData"
              :key="index"
            >
              <!-- <div class="item-name">{{ item.fileName }}</div> -->
              <div class="applicationData-item-main">
                <div class="item-name">
                  <a
                    :href="
                      '/web/web/preview/previewfile?download=1&filePath=' +
                      item.filePath
                    "
                    >{{ item.fileName }}</a
                  >
                </div>
                <div class="item-applicationName">{{ mediatorname }}</div>
                <div class="item-time">{{ item.createTime }}</div>
              </div>
              <div
                v-if="item.filePath.slice(-3) == 'pdf'"
                class="applicationData-item-preview"
              >
                <pdf
                  v-for="i in numPages"
                  :key="i"
                  :page="i"
                  ref="pdf"
                  :src="pdfUrl"
                ></pdf>
              </div>
              <div
                v-if="item.filePath.slice(-3) == 'png'"
                class="applicationData-item-preview"
              >
                <iframe
                  :src="pngUrl"
                  frameborder="0"
                  width="100%"
                  height="600"
                ></iframe>
              </div>

              <!-- <div class="item-lookBtn"> -->
              <!-- pdf or 图片 可查看可下载 -->
              <!-- <div v-if="item.filePath.slice(-3) == 'pdf'">
                  <el-button class="look-file" type="text" @click="lookfile" size="small"
                    >查看</el-button
                  >
                  <a
                    target="noDwonLoad"
                    :href="`/web/web/preview/previewfile?download=1&filePath=${item.filePath}`"
                  >
                    <el-button type="text" size="small">下载</el-button>
                  </a>
                  <iframe style="display: none" name="noDwonLoad"></iframe>
                </div> -->
              <!-- 不是pdf 只有下载 -->
              <!-- <div v-if="item.filePath.slice(-3) != 'pdf'">
                  <a
                    target="noDwonLoad"
                    :href="`/web/web/preview/previewfile?download=1&filePath=${item.filePath}`"
                  >
                    <el-button type="text" size="small">下载</el-button>
                  </a>
                  <iframe style="display: none" name="noDwonLoad"></iframe>
                </div> -->
              <!-- </div> -->
            </div>
          </div>
          <div class="empty" v-else>
            <div class="empty-img">
              <img src="../assets/images/index/empty.png" />
            </div>
            <div class="empty-text">暂无相关数据</div>
          </div>
          <!-- </el-table> -->
        </el-tab-pane>
        <el-tab-pane label="受理材料">
          <div class="acceptanceData-content" v-if="acceptanceData.length > 0">
            <div
              class="acceptanceData-item"
              v-for="(item, index) in acceptanceData"
              :key="index"
            >
              <div>
                <a
                  :href="
                    '/web/web/preview/previewfile?download=1&filePath=' +
                    item.filePath
                  "
                  >{{ item.fileName }}</a
                >
              </div>
              <div v-if="item.filePath.slice(-3) == 'pdf'">
                <pdf
                  v-for="i in numPagesTwo"
                  :key="i"
                  :page="i"
                  ref="pdf"
                  :src="pdfUrlTwo"
                ></pdf>
              </div>
              <div v-if="item.filePath.slice(-3) == 'png'">
                <iframe
                  :src="pngUrlTwo"
                  frameborder="0"
                  width="100%"
                  height="600"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <div class="empty-img">
              <img src="../assets/images/index/empty.png" />
            </div>
            <div class="empty-text">暂无相关数据</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="调解意见书">
          <div class="respondentData-content" v-if="respondentData.length > 0">
            <div
              class="respondentData-item"
              v-for="(item, index) in respondentData"
              :key="index"
            >
              <!-- <a
                target="noDwonLoad"
                :href="`/web/web/preview/previewfile?download=1&filePath=${itemss.filePath}`"
                >{{ itemss.fileName }}</a
              >
              <iframe style="display: none" name="noDwonLoad"></iframe> -->
              <div>
                <a
                  :href="
                    '/web/web/preview/previewfile?download=1&filePath=' +
                    item.filePath
                  "
                  >{{ item.fileName }}</a
                >
              </div>
              <div v-if="item.filePath.slice(-3) == 'pdf'">
                <pdf
                  v-for="i in numPagesThree"
                  :key="i"
                  :page="i"
                  ref="pdf"
                  :src="pdfUrlThree"
                ></pdf>
              </div>
              <div v-if="item.filePath.slice(-3) == 'png'">
                <iframe
                  :src="pngUrlThree"
                  frameborder="0"
                  width="100%"
                  height="600"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <div class="empty-img">
              <img src="../assets/images/index/empty.png" />
            </div>
            <div class="empty-text">暂无相关数据</div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="司法确认材料">
          <el-table
            :data="judicialData"
            :show-header="false"
            style="width: 100%"
          >
            <template slot="empty">
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </template>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="归档材料">
          <el-table :data="fileData" :show-header="false" style="width: 100%">
            <template slot="empty">
              <div class="empty-img">
                <img src="../assets/images/index/empty.png" />
              </div>
              <div class="empty-text">暂无相关数据</div>
            </template>
          </el-table>
        </el-tab-pane> -->
      </el-tabs>
    </div>

    <el-dialog
      title="预览文件"
      :visible.sync="visible"
      :fullscreen="true"
      top="0"
    >
      <pdf ref="pdf" :src="pdfUrl"></pdf>
      <!-- <iframe :src="pdfUrl" frameborder="0" style="width: 100%; height: 100%"></iframe> -->
    </el-dialog>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  name: "LookCaseDetail",
  components: { pdf },
  data() {
    return {
      visible: false,
      active: 1,
      activeMenu: 0,
      id: "",
      respondentsname: "",
      mediatorname: "", // 申请调解人

      pdfUrl: "", // 预览pdf
      pdfUrlTwo: "",
      pdfUrlThree: "",

      pngUrl: "", // 预览图片
      pngUrlTwo: "",
      pngUrlThree: "",

      numPages: null,
      numPagesTwo: null,
      numPagesThree: null, // 当pdf文件页数很多  加载就会慢

      lookcasedetailData: [],
      applicationData: [],
      // 受理材料
      acceptanceData: [],
      //被申请人调解材料
      respondentData: [],
      //司法确认材料
      judicialData: [],
      // 归档材料
      fileData: [],
      caseInfo: {},
    };
  },
  mounted() {
    // console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.getCaseInfo();
    // this.getDefenceDetail();
    this.getServiceDetail();
  },
  methods: {
    // 点击查看文件
    lookfile() {
      this.visible = true;
    },
    // 送达详情
    getServiceDetail() {
      this.$axios
        .get(`/web/web/serviceLog/info?mdCaseId=${this.id}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.lookcasedetailData = res.data.data;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },

    handleClick(e) {
      //   console.log(e);
    },
    getDefenceDetail() {
      this.$axios
        .get(`/web/web/caseReplyLog/info?caseId=${this.id}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.respondentData = res.data.data;
            // res.data.data.map(item => {
            // console.log(item);
            // this.respondentData = item.filePathList
            // })
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    //获取案件详情
    getCaseInfo() {
      let caseId = this.id;
      // console.log(caseId);
      this.$axios
        .get(`/web/web/case/info?id=${caseId}`)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.caseInfo = res.data.data;
            if (
              this.caseInfo.mdProposerFileList &&
              (this.caseInfo.mdProposerFileList != "" ||
                this.caseInfo.mdProposerFileList != null)
            ) {
              this.applicationData = this.caseInfo.mdProposerFileList;
            }
            if (
              this.caseInfo.acceptFileList &&
              (this.caseInfo.acceptFileList != "" ||
                this.caseInfo.acceptFileList != null)
            ) {
              this.acceptanceData = this.caseInfo.acceptFileList;
            }
            if (
              this.caseInfo.mdRespondentFileList &&
              (this.caseInfo.mdRespondentFileList != "" ||
                this.caseInfo.mdRespondentFileList != null)
            ) {
              this.respondentData = this.caseInfo.mdRespondentFileList;
            }

            this.applicationData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrl = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlTask = pdf.createLoadingTask(this.pdfUrl);
                pdfUrlTask.promise
                  .then((pdf) => {
                    this.numPages = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrl = `/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.acceptanceData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrlTwo = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlTwoTask = pdf.createLoadingTask(this.pdfUrlTwo);
                pdfUrlTwoTask.promise
                  .then((pdf) => {
                    this.numPagesTwo = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrlTwo = `/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.respondentData.map((item) => {
              if (item.filePath.slice(-3) == "pdf") {
                this.pdfUrlThree = `/web/web/preview/previewfile?download=1&filePath=${item.filePath}`;
                let pdfUrlThreeTask = pdf.createLoadingTask(this.pdfUrlThree);
                pdfUrlThreeTask.promise
                  .then((pdf) => {
                    this.numPagesThree = pdf.numPages;
                  })
                  .catch((err) => {
                    this.$message({
                      message: err || "加载失败，pdf文件为空或者损坏",
                      type: "error",
                    });
                  });
              }
              if (
                item.filePath.slice(-3) == "png" ||
                item.filePath.slice(-3) == "jpg" ||
                item.filePath.slice(-3) == "jpeg"
              ) {
                this.pngUrlThree = `/web/preview/previewfile?filePath=${item.filePath}`;
              }
            });

            this.mediatorname = this.caseInfo.mdProposer.name;
            let mdRespondents = this.caseInfo.mdRespondents;
            if (this.caseInfo.status == 0) {
              this.active = 1;
            }
            if (this.caseInfo.status == 1 || this.caseInfo.status == 2) {
              this.active = 2;
            }
            if (this.caseInfo.status == 3) {
              this.active = 3;
            }
            if (this.caseInfo.status == 4) {
              this.active = 4;
            }
            if (this.caseInfo.status == 5 || this.caseInfo.status == -1) {
              this.active = 5;
            }
            for (var i = 0; i < mdRespondents.length; i++) {
              if (mdRespondents.length > 1) {
                this.respondentsname =
                  mdRespondents[i].name + "," + mdRespondents[i + 1].name;
              } else {
                this.respondentsname = mdRespondents[i].name;
              }
            }
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          return;
          // this.$message({
          //   message: "请求异常，服务器出错",
          // });
        });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/lookcasedetail.scss";
</style>